<template>
  <div>
    <v-card v-if="selectedEvent.idEvento" min-width="400px" flat>
      <v-toolbar :color="(selectedEvent.color || '')" dark>
        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
        <v-spacer style="min-width: 15px"></v-spacer>
        <v-btn
          :disabled="!selectedEvent.editable"
          color="primary"
          :elevation="0"
          rounded
          :to="`/eventos/${selectedEvent.idEvento}`"
          class="mr-1"
        >
          <v-icon left>mdi-pencil</v-icon>
          editar
        </v-btn>
        <v-btn
          v-if="selectedEvent.estado == 'Anulado'"
          color="error"
          :elevation="0"
          rounded
          @click.stop="deleteEvent(selectedEvent.idEvento)"
        >
          <v-icon left>mdi-delete</v-icon>
          eliminar
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-chip
          class="v-chip--active font-weight-bold"
          outlined
          label
          large
          :color="selectedEvent.colorTipoEvento"
        >
          <v-icon left>{{ selectedEvent.iconTipoEvento }}</v-icon>
          {{ selectedEvent.tipoEvento }}
        </v-chip>
        <v-chip
          class="v-chip--active font-weight-bold ml-2"
          outlined
          label
          large
          :color="selectedEvent.colorEstadoEvento"
        >
          <v-icon left>{{ selectedEvent.iconEstadoEvento }}</v-icon>
          {{ selectedEvent.estado }}
        </v-chip>
        <v-chip
          class="v-chip--active font-weight-bold ml-2"
          outlined
          label
          large
          color="primary"
          @click.stop="$emit('cambioEstado', selectedEvent.idEvento)"
        >
        <v-icon left small>mdi-pencil</v-icon>
         Cambiar Estado
        </v-chip>
        <v-chip
          class="v-chip--active font-weight-bold ml-2"
          outlined
          label
          large
          color="error"
          @click="deleteEvent(selectedEvent.idEvento)"
        >
          <v-icon left>mdi-trash-can</v-icon>
          eliminar
        </v-chip>
        <v-divider class="my-2"></v-divider>
        <h4>
          {{
            [selectedEvent.nombreEspacio, selectedEvent.nombreSalon]
              .filter((prop) => prop != null && prop != "null" && prop.trim() != "")
              .join(" - ")
          }}
        </h4>
      </v-card-text>
      <v-divider></v-divider>
      <div class="d-flex flex-justify-space-between align-center mx-2">
        <v-card-text>Trello: </v-card-text>
        <v-btn
          v-if="selectedEvent.trelloLink && !trelloLinkEdit"
          outlined
          small
          :href="selectedEvent.trelloLink"
          target="_blank"
        >
          <v-icon left small>mdi-open-in-new</v-icon>
          <span class="text-truncate d-block" style="max-width: 100px">
            {{ selectedEvent.trelloLink }}
          </span>
        </v-btn>
        <v-text-field
          v-if="trelloLinkEdit"
          class="flex-1"
          filled
          dense
          hide-details
          v-model="selectedEvent.trelloLink"
          @change="saveTrelloLink(selectedEvent)"
        ></v-text-field>
        <v-btn
          @click.stop="trelloLinkEdit = !trelloLinkEdit"
          class="ml-1"
          small
          icon
          outlined
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text color="error" @click="selectedOpen = false"> Cerrar </v-btn>
        <v-btn
          text
          color="primary"
          :to="`/eventos?filter=${selectedEvent.idEvento}`"
        >
          Ver en listado
        </v-btn>
        <v-spacer></v-spacer>
        <v-chip
          class="v-chip--active font-weight-bold ml-2"
          outlined
          label
          large
          color="primary"
          @click.stop="$emit('cambioFeedback', selectedEvent.idEvento)"
        >
          <v-icon left>mdi-pencil</v-icon>
          Encuesta
        </v-chip>
        <v-chip
          class="v-chip--active font-weight-bold ml-2"
          outlined
          label
          large
          :color="(this.feedback.find(r => r.val == selectedEvent.datos.feedback)?.color || '')"
          @click.stop="$emit('cambioFeedback', selectedEvent.idEvento)"
        >
          <v-icon left>{{ (this.feedback.find(r => r.val == selectedEvent.datos.feedback)?.icon || '') }}</v-icon>
          {{ (this.feedback.find(r => r.val == selectedEvent.datos.feedback)?.title || '') }}
        </v-chip>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="selectedEvent.festivo">
      <v-toolbar dense :color="(selectedEvent.color || '')" dark>
        <v-toolbar-title>
          <b>Festivo:&nbsp;</b>
          {{ selectedEvent.name }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-subheader>Localidades</v-subheader>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            v-for="loc in selectedEvent.localidad
              ? selectedEvent.localidad.split(',')
              : []"
            :key="loc"
            dense
          >
            <v-list-item-title>
              {{ loc }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-else-if="selectedEvent.jornada">
      <v-toolbar dense :color="(selectedEvent.color || '')" dark>
        <v-toolbar-title>
          <b>Jornada:&nbsp;</b>
          {{ selectedEvent.name }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-subheader>Espacio</v-subheader>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            v-for="loc in selectedEvent.espacio
              ? selectedEvent.espacio.split(',')
              : []"
            :key="loc"
            dense
          >
            <v-list-item-title>
              {{ loc }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
export default {
  props: {
    selectedEvent: Object,
  },
  data() {
    return {
      trelloLinkEdit: false,
      feedback:[
        {title:"NS / NC", val : 1, icon: 'mdi-help', color: 'black'}, 
        {title:"Valoración Negativa", val : 2, icon: 'mdi-emoticon-sad-outline', color: 'error'},
        {title:"Valoración Neutra", val : 3, icon: 'mdi-emoticon-neutral-outline',color: 'warning'},
        {title:"Valoración Positiva", val : 4, icon: 'mdi-emoticon-happy-outline',color: 'success'},
      ],
    };
  },
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  methods: {
    async deleteEvent(idEvento) {
      const confirmation = await this.$refs.confirm.open(
        "Eliminar el evento",
        "¿Estás seguro? <br><br> Esta acción no se puede deshacer",
        { color: "error" }
      );
      if (!confirmation) return;

      try {
        const { data } = await axios({
          url: `/eventos/${idEvento}`,
          method: "DELETE",
        });
        this.$root.$emit("snack", "El evento se ha eliminado correctamente");
        this.$emit("reload");
      } catch (error) {
        this.$root.$emit("snack", "No se ha podido eliminar el evento");
        console.error(error);
      }
    },
    async saveTrelloLink(ev) {
      await axios({
        url: `/eventos/${ev.idEvento}`,
        method: "PATCH",
        data: {
          trelloLink: ev.trelloLink,
        },
      });
      this.trelloLinkEdit = false;
    },
  },
};
</script>

<style>
</style>